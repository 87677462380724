import React, { useEffect, useState } from "react";
import { loadRepresentation } from "core/parser";
import StoreLocator, { STORE_LOCATOR_PROPS, } from "./_StoreLocator";
import { getDistanceFn } from "main/utils/position";
import { missingMandatoryProp, missingProps } from "main/utils/errors";
import { centerChangedHandler, filteredStoresChangedHandler } from "core/events";
import { ToastContainer } from "react-toastify";
function StoreLocatorVariant1({ currentDevice, allStores, allServices, autocomplete, mapZoom, setMapZoom, anchorPoints, children, openingTimesCache, selectedStore, setSelectedStore, position: { position, setPosition, geolocateUser, useUserPosition }, directions: { resetDirections, origin, destinationStore, travelMode, setStore, setDirectionsInfo, setTravelMode }, filters: { filter, setFilter }, }) {
    const [filteredStores, setFilteredStores] = useState([]);
    const [visibleStores, setVisibleStores] = useState([]);
    const [selectedDistance, setSelectedDistance] = useState(10);
    useEffect(() => {
        function centerChangedListener(event) {
            console.log(event.detail);
            const center = event.detail.mapCenter;
            const getDistance = getDistanceFn(center.latitude, center.longitude);
            const storesInRange = filteredStores.filter((s) => getDistance(s.latitude, s.longitude) < selectedDistance);
            setVisibleStores(storesInRange);
        }
        function filteredStoresChangedListener(event) {
            console.log("filteredStores changed: ", event.detail);
        }
        centerChangedHandler.addListener(centerChangedListener);
        filteredStoresChangedHandler.addListener(filteredStoresChangedListener);
        return () => {
            centerChangedHandler.removeListener(centerChangedListener);
            filteredStoresChangedHandler.removeListener(filteredStoresChangedListener);
        };
    }, [filteredStores]);
    useEffect(() => {
        const servicesPred = (s) => !filter.services || filter.services.every((serviceId) => s.services.includes(serviceId));
        const storeTypesPred = (s) => !filter.storeTypes || filter.storeTypes.some((storeType) => s.storeTypes.includes(storeType));
        const filteredStores = allStores.filter((s) => storeTypesPred(s) && servicesPred(s));
        setFilteredStores(filteredStores);
    }, [filter]);
    return (React.createElement(React.Fragment, null, children({
        currentDevice,
        allStores,
        allServices,
        autocomplete,
        mapZoom,
        setMapZoom,
        anchorPoints,
        currentPosition: position.currentPosition,
        destinationStore,
        directionsOrigin: origin,
        filteredStores: visibleStores,
        filter,
        setFilter,
        geolocateUser,
        openingTimesCache,
        useUserPosition,
        selectedDistance,
        setSelectedDistance,
        resetDirections,
        setDirectionsStore: setStore,
        setDirectionsInfo,
        setTravelMode,
        setPosition,
        selectedStore,
        setSelectedStore,
        setFilteredStores,
        setVisibleStores,
        travelMode,
        visibleStores,
        userPosition: position.userPosition,
    })));
}
function Component(props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(ToastContainer, { position: "top-right", pauseOnHover: true, autoClose: 3000 }),
        React.createElement(StoreLocator, { StoreLocatorVariant: StoreLocatorVariant1, ...props })));
}
export const dynamicComponent = {
    Component,
    children: (repr, configuration) => (props) => loadRepresentation(repr, configuration, props),
    checkExpectedProps: (props) => {
        const componentName = "StoreLocator (variant 1)";
        if (!props)
            throw new Error(missingProps(componentName));
        const mandatoryProps = Object.values(STORE_LOCATOR_PROPS).filter((v) => v !== STORE_LOCATOR_PROPS.CHILDREN);
        const actualProps = Object.keys(props);
        mandatoryProps.forEach((prop) => {
            if (!actualProps.includes(prop)) {
                throw new Error(missingMandatoryProp(componentName, prop));
            }
        });
    },
};
