import React, { useRef } from "react";
import { missingMandatoryProp, missingProps } from "utils/errors";
import { analyticsHandler, storeCardClickHandler } from "core/events";
function Component({ store, setDirectionsStore, openingTimesCache, 
// selectedStore,
// setSelectedStore,
configuration, }) {
    const cardRef = useRef(null);
    // useEffect(() => {
    //     if (selectedStore === store.storeCode) {
    //         setTimeout(() => cardRef.current?.scrollIntoView(), 300);
    //     }
    // }, [selectedStore]);
    function handleStoreCardClick() {
        storeCardClickHandler.dispatch({
            activeStore: store.storeCode,
        });
        // setSelectedStore(store.storeCode);
    }
    function handleStoreInfoRequest(e) {
        e.stopPropagation();
        analyticsHandler.dispatch({
            type: "click_store_details",
            subtype: "listing",
            payload: { store },
        });
        window.open(store.storeLink, "_blank");
    }
    function handleDrivingDirectionsRequest(e) {
        e.stopPropagation();
        analyticsHandler.dispatch({
            type: "click_directions",
            subtype: "listing",
            payload: { store },
        });
        let url = store.googleMapsLink;
        if (url == "")
            url = "https://www.google.com/maps/dir//" + store.latitude + "," + store.longitude;
        console.log(url);
        window.open(url, "_blank");
    }
    function handleWhatsappClick() {
        analyticsHandler.dispatch({
            type: "click_whatsapp",
            subtype: "listing",
            payload: { store },
        });
    }
    function handlePhoneClick() {
        analyticsHandler.dispatch({
            type: "click_phone",
            subtype: "listing",
            payload: { store },
        });
    }
    return (React.createElement("li", { ref: cardRef, className: "rt-store", onClick: handleStoreCardClick },
        React.createElement("section", { className: "rt-store__info" },
            React.createElement("h3", { className: "rt-store__info-name" }, store.name),
            React.createElement("p", { className: "rt-store__info-address" }, store.address1),
            React.createElement("p", { className: "rt-store__city" },
                store.postalCode,
                ", ",
                store.city),
            store.phone && (React.createElement("a", { className: "rt-store__info-phone", href: `tel:${store.phone}`, onClick: handlePhoneClick }, store.phone)),
            store.whastapp && (React.createElement("a", { className: "rt-store__info-whatsapp", href: `wa.me/${store.whastapp}`, onClick: handleWhatsappClick }, configuration.translations.storeCard.whatsapp)),
            openingTimesCache.has(store.storeCode) && (React.createElement("p", { className: "rt-store__info-openings" }, openingTimesCache.get(store.storeCode)))),
        React.createElement("div", { className: "rt-store__buttons" },
            React.createElement("button", { className: "rt-store__button-details", onClick: handleStoreInfoRequest }, configuration.translations.storeCard.info),
            React.createElement("button", { className: "rt-store__button-directions", onClick: handleDrivingDirectionsRequest }, configuration.translations.storeCard.directions))));
}
export const dynamicComponent = {
    Component,
    checkExpectedProps: (props) => {
        const componentName = "StoreCard (variant 1)";
        if (!props)
            throw new Error(missingProps(componentName));
        if (!props.store)
            throw new Error(missingMandatoryProp(componentName, "store"));
        if (!props.setDirectionsStore)
            throw new Error(missingMandatoryProp(componentName, "setDirectionsStore"));
        if (!props.openingTimesCache)
            throw new Error(missingMandatoryProp(componentName, "openingTimesCache"));
        if (!props.configuration)
            throw new Error(missingMandatoryProp(componentName, "configuration"));
    },
    filterProps: (props) => {
        if (!props)
            throw new Error(missingProps("StoreCard (variant 1)"));
        const neededProps = [
            "store",
            "setDirectionsStore",
            // "selectedStore",
            // "setSelectedStore",
            "openingTimesCache",
            "configuration",
        ];
        return Object.fromEntries(Object.entries(props).filter(([key, _]) => neededProps.includes(key)));
    },
    transformConfiguration: (config) => {
        const storeCardConfig = {
            time: {
                serverTime: config.time.serverTime,
                hours: config.time.hours,
                minutes: config.time.minutes,
            },
            translations: {
                openingTimes: config.translations.openingTimes,
                storeCard: config.translations.list.storeCard,
            },
        };
        return storeCardConfig;
    },
};
